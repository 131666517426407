.Controls-container {
  --tab-width: 134px;
  --content-width: calc(
    var(--column-width) - calc(var(--default-spacing) + var(--tab-width))
  );
}

.Controls-terminal,
.Controls-jobdefs {
  display: grid;
  grid-template-columns: [tabs] var(--tab-width) [job] var(--content-width);
  column-gap: var(--default-spacing);
}

.Controls-jobdefs {
  border-top: 1px solid #a8a8a8;
}

.Controls-terminal-label-container {
  display: flex;
  align-items: center;
}

.Controls-terminal-label {
  flex: auto;
  text-align: right;
}

.Controls-terminal-form {
  padding-right: 8px;
}

.Controls-jobdefs-forms {
  padding: 0px 8px 0px 0px;
}

.Controls-inputbox {
  /* TODO rename */
  display: grid;
  grid-template-columns: [field] calc(var(--content-width) - 8px - 8px - 64px) [button] 64px;
  column-gap: 8px;
  padding: 8px 0;
}

.Controls-inputfield {
  line-height: 18px;
  padding: 4px 8px;
}
