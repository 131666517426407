.JobTabs-tab {
  padding: 6px 12px;
  text-align: center;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.JobTabs-pick {
  border-bottom: 1px solid #a8a8a8;
  border-right: 1px solid #a8a8a8;
}

.JobTabs-selectable {
  cursor: pointer;
}

.JobTabs-selectable:hover {
  color: white;
  background-color: #606060;
}

.JobTabs-tab.JobTabs-pick--selected {
  border-right: 0px;
}
