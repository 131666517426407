/* #606060 */

.JobEditor-headerline {
  display: grid;
  grid-template-columns: [header] 1fr [save] 40px [play] 40px [stop] 40px;
  padding: 8px 0;
}

.JobEditor-header {
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.JobEditor-body {
    padding-bottom: 8px;
}

.JobEditor-body .DraftEditor-editorContainer {
  border: 1px solid #a8a8a8;
  border-style: inset;
  padding: 10px;
}

.JobEditor-body .public-DraftEditor-content {
  font-family: monospace;
  min-height: 150px;
}
