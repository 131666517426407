/*
    variables and calcluations seem to interact with
    "margin: auto" in creepy ways, so we repeat the
    same values a lot here.
*/

.App-header-container {
  background-color: white;
  border-bottom: 1px solid black;
  margin: 0 0 var(--default-spacing) 0;
  box-shadow: 1px 2px 3px -2px #101010;
}

.App-header {
  margin: 0 auto;
  font-size: 14px;
  font-weight: normal;
  font-family: "chicagoflfregular", sans-serif;
  line-height: 18px;
  width: calc(386px + var(--default-spacing) + 386px);
}

.App-window {
  background-color: white;
  border: 1px solid black;
  box-shadow: 1px 2px 3px -2px #101010;
}

.App-controls {
  --column-width: 384px;
}

.App-feedback {
  --column-width: 384px;
}
